import React from 'react';
import { Layout } from '../../components/Layout';
import { LoaderCard } from '../../components/Loaders';

const HomeAutosCar = () => (
  <Layout>
    <LoaderCard />
  </Layout>
);

export default HomeAutosCar;
